import React from "react"
import { Input } from "reactstrap"
import { Button } from "components/Button"

const AuditItemModal = ({ onChange, value, isLoading, onSubmit, max }) => {
    console.log("🚀 ~ AuditItemModal ~ value:", value)
    return (
        <form onSubmit={onSubmit}>
            <div className="d-flex align-items-center justify-content-center flex-column">
                <Input
                    onKeyDown={e => preventUnwantedInput(e)}
                    required
                    type="number"
                    value={value}
                    onChange={onChange}
                    step={"0.01"}
                    min={0}
                    max={max}
                />
                <div>
                    <Button
                        title="Update"
                        type="submit"
                        className="gt-btn-grad-primary mt-2"
                        isLoading={isLoading}
                    />
                </div>
            </div>
        </form>
    )
}

export default AuditItemModal
