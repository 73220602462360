import {
    Login,
    GetMe,
    ForgotPassword,
    ForgotPasswordVerification,
    ResetPassword,
    Logout,
    ResendOtpCode,
} from "api/api.service"
import { SET_SELECTED_DEPARTMENT } from "./departmenConfigActions"
import { UserTypes } from "../../constants"

export const ME_REQUEST = "ME_REQUEST"
export const ME_SUCCESS = "ME_SUCCESS"
export const ME_UPDATE = "ME_UPDATE"
export const ME_ERROR = "ME_ERROR"
export const SET_TOKEN = "SET_TOKEN"
export const SIGN_OUT = "SIGN_OUT"

export const getMe = () => (dispatch, getState) => {
    const token = getState().userAuth.user.Token
    dispatch({ type: ME_REQUEST })
    return GetMe()
        .then(async res => {
            if (res.type == UserTypes.DEPARTMENT) {
                let currentDepartment =
                    getState().departmentConfig.currentSelectedDepartment

                if (!currentDepartment) {
                    // var firstDepartment = res.departmentAssignments[0]
                    // firstDepartment = {
                    //     ...firstDepartment,
                    //     label: firstDepartment.department.departmentName,
                    //     value: firstDepartment.department.id,
                    // }
                    dispatch({
                        type: SET_SELECTED_DEPARTMENT,
                        payload: {
                            label: "All Stations",
                            value: "all",
                        },
                    })
                }
            }

            dispatch({ type: ME_SUCCESS, payload: res })
            localStorage.isAuthenticated = true

            return Promise.resolve(res)
        })
        .catch(err => {
            dispatch({ type: ME_ERROR })
            return Promise.reject(err)
        })
}

export const login =
    ({ Email, Password, Url, Type }) =>
    (dispatch, getState) => {
        return Login({
            email: Email,
            password: Password,
            url: Url,
        })
            .then(async res => {
                dispatch({ type: SET_TOKEN, token: res.token })
                await dispatch(getMe())
                return Promise.resolve(res)
            })
            .catch(err => {
                return Promise.reject(err)
            })
    }

export const forgotPassword =
    ({ Email }) =>
    (dispatch, getState) => {
        return ForgotPassword({ Email })
            .then(async res => {
                return Promise.resolve(res)
            })
            .catch(err => {
                return Promise.reject(err)
            })
    }

export const forgotPasswordVerification =
    ({ Code, TokenUUID }) =>
    (dispatch, getState) => {
        return ForgotPasswordVerification({ Code, TokenUUID })
            .then(async res => {
                return Promise.resolve(res)
            })
            .catch(err => {
                return Promise.reject(err)
            })
    }

export const resetPassword =
    ({ Code, TokenUUID, Password }) =>
    (dispatch, getState) => {
        return ResetPassword({ Code, TokenUUID, Password })
            .then(async res => {
                return Promise.resolve(res)
            })
            .catch(err => {
                return Promise.reject(err)
            })
    }

export const resendOtpCode =
    ({ TokenUUID }) =>
    (dispatch, getState) => {
        return ResendOtpCode({ TokenUUID })
            .then(async res => {
                return Promise.resolve(res)
            })
            .catch(err => {
                return Promise.reject(err)
            })
    }

export const logout = () => (dispatch, getState) => {
    return Logout()
        .then(async res => {
            return Promise.resolve(res)
        })
        .catch(err => {
            return Promise.reject(err)
        })
}
