import { Get, Put, Post, Delete } from "./api.helper"
import { store } from "store"
import Config from "Config"
import { appendQueryParams } from "utils/urlUtils"
import { handleFetchError } from "utils/errorHandler"

export const Login = (bodyData = {}, showToaster = true) => {
    return Post({
        url: "/auth/login",
        body: bodyData,
        isAuthorized: false,
        showToaster,
    })
}

export const GetMe = () => {
    return Get({ url: "/user" })
}

export const ForgotPassword = (bodyData = {}, showToaster = true) => {
    return Post({
        url: "/auth/forget-password",
        body: bodyData,
        showToaster,
    })
}
export const ChangePassword = (bodyData = {}, showToaster = true) => {
    return Post({
        url: "/user/change-password",
        body: bodyData,
        showToaster,
    })
}

export const ForgotPasswordVerification = (
    bodyData = {},
    showToaster = true
) => {
    return Post({
        url: "/auth/forget-password/verification",
        body: bodyData,
        showToaster,
    })
}

export const ResetPassword = (bodyData = {}, showToaster = true) => {
    return Post({
        url: "/auth/reset-password",
        body: bodyData,
        showToaster,
    })
}

export const ResendOtpCode = (bodyData = {}, showToaster = true) => {
    return Post({
        url: "/auth/resend-otp",
        body: bodyData,
        showToaster,
    })
}

export const Logout = () => {
    return Post({
        url: "/user/logout",
    })
}

//**** WAREHOUSE DASHBOARD APIS
export const WarehouseDashboardAnalytics = () => {
    return Get({
        url: "/inventory/dashboard",
    })
}

export const GetStockListing = (params = {}) => {
    return Get({
        url: "/inventory/dashboard-shortage",
        params,
    })
}

export const WarehouseInventoryListing = (params = {}, abortSignal = null) => {
    return Get({
        url: "/inventory/warehouse-inventory",
        params,
        abortSignal,
    })
}

// export const DepartmentInventoryListing = ( params = {}, abortSignal = null ) => {
//   return Get({
//     url: "/inventory/department-inventory",
//     params,
//     abortSignal,
//   })
// }

export const ItemCategoryCategoriesListing = (
    params = {},
    abortSignal = null
) => {
    return Get({
        url: "/items-category/categories",
        params,
        abortSignal,
    })
}

export const GetDepartmentInventoryListing = (
    params = {},
    abortSignal = null
) => {
    return Get({ url: "/inventory/department-inventory", params, abortSignal })
}

export const GetDepartmentGroupedInventoryListing = (
    params = {},
    abortSignal = null
) => {
    return Get({
        url: "/inventory/group-department-inventory",
        params,
        abortSignal,
    })
}

export const GetDepartmentInventoryRequestListing = (
    params = {},
    abortSignal = null
) => {
    return Get({ url: "/inventory/department-requests", params, abortSignal })
}

export const AddDepartmentInventoryRequest = (
    bodyData = {},
    showToaster = true
) => {
    return Post({
        url: "/inventory/department-requests",
        body: bodyData,
        showToaster,
    })
}

export const AddWarehouseInventoryRequest = (
    bodyData = {},
    showToaster = true
) => {
    return Post({
        url: "/inventory/warehouse-requests",
        body: bodyData,
        showToaster,
    })
}

export const PostAssignInventoryToDepartment = (
    bodyData = {},
    showToaster = true
) => {
    return Post({
        url: "/warehouse/department-fullfillment",
        body: bodyData,
        showToaster,
    })
}

export const GetDepartmentsListing = (params = {}, abortSignal = null) => {
    return Get({ url: "/department/departments", params, abortSignal })
}

export const GetDepartmentById = (id = "") => {
    return Get({ url: `/department/${id}` })
}

export const GetItemsListing = (params = {}, abortSignal = null) => {
    return Get({ url: "/item/items", params, abortSignal })
}

export const GetItemDetailsById = (id = "") => {
    return Get({ url: `/item/${id}` })
}

export const GetInventoryItemById = (id = "") => {
    return Get({ url: `/inventory/items-by-id/${id}` })
}

export const GetWarehouseToFinanceRequestsListing = (
    params = {},
    abortSignal = null
) => {
    return Get({ url: "/inventory/warehouse-requests", params, abortSignal })
}

export const GetWarehouseToFinanceRequestsListingItems = (
    params = {},
    abortSignal = null,
    id = ""
) => {
    return Get({
        url: `/inventory/warehouse-requests-items/${id}`,
        params,
        abortSignal,
    })
}

export const GetWarehouseToFinanceRequestsListingItemsReceiving = (
    params = {},
    abortSignal = null,
    id = ""
) => {
    return Get({
        url: `/inventory/warehouse-requests-items-receivings/${id}`,
        params,
        abortSignal,
    })
}

export const DepartmentFulfillmentByWarehouse = (
    bodyData = {},
    showToaster = true
) => {
    return Post({
        url: "/inventory/department-request",
        body: bodyData,
        showToaster,
    })
}

export const MarkInventoryCommitted = (bodyData = {}, showToaster = true) => {
    return Post({
        url: "/inventory/committed-item",
        body: bodyData,
        showToaster,
    })
}

export const MarkInventoryUsage = (bodyData = {}, showToaster = true) => {
    return Post({
        url: "/inventory/usage-item",
        body: bodyData,
        showToaster,
    })
}

export const ItemCategoriesListing = (params = {}, abortSignal = null) => {
    return Get({
        url: "/items-category/categories",
        params,
        abortSignal,
    })
}

export const UpdateItemCategory = (id = "", bodyData = {}) => {
    return Put({
        url: `/items-category/${id}`,
        body: bodyData,
    })
}

export const UpdateItem = (id = "", bodyData = {}) => {
    return Put({
        url: `/item/${id}`,
        body: bodyData,
    })
}

export const AddItemCategory = (bodyData = {}) => {
    return Post({
        url: "/items-category",
        body: bodyData,
    })
}

export const GetItemById = (id = "") => {
    return Get({ url: `/item/${id}` })
}

export const AddNewItem = (bodyData = {}, showToaster = true) => {
    return Post({
        url: "/item",
        body: bodyData,
        showToaster,
    })
}

export const AddSelectedItem = (bodyData = {}, showToaster = true) => {
    return Post({
        url: "/item/synchronize-items",
        body: bodyData,
        showToaster,
    })
}

export const UpdateSelectedItem = (bodyData = {}, showToaster = true) => {
    return Put({
        url: "/item/bulk-items",
        body: bodyData,
        showToaster,
    })
}

export const GetWarehouseInventoryPODetails = (id = "") => {
    return Get({ url: `/inventory/items-purchase-orders/${id}` })
}

export const GetScrapReturnListing = (params = {}, abortSignal = null) => {
    return Get({ url: "/requests/scrap-return", params, abortSignal })
}

export const GetWarehouseScrapReturnListing = (
    params = {},
    abortSignal = null
) => {
    return Get({ url: "/requests/warehouse-scrap-return", params, abortSignal })
}

export const CreateScrapReturnRequest = (bodyData = {}) => {
    return Post({
        url: "/requests/scrap-return",
        body: bodyData,
    })
}

export const GetScrapReturnRequestById = (id = "", params = {}) => {
    return Get({ url: `/requests/scrap-return/${id}`, params })
}

export const GetPullbackRequestListing = (params = {}, abortSignal = null) => {
    return Get({ url: "/requests/pullback-listing", params, abortSignal })
}

export const CreatePullbackRequest = (bodyData = {}) => {
    return Post({
        url: "/requests/pullback",
        body: bodyData,
    })
}

export const PullbackFullfillment = (bodyData = {}, showToaster = true) => {
    return Post({
        url: "/requests/pullback-fullfillment",
        body: bodyData,
        showToaster,
    })
}

// Notification
export const GetNotifications = (params = {}, abortSignal = null) => {
    return Get({
        url: "/notification",
        params,
        abortSignal,
    })
}

export const MarkNotificationsAsRead = (bodyData = {}, abortSignal = null) => {
    return Post({
        url: `/notification/read-notifications`,
        body: bodyData,
        abortSignal,
    })
}

// setting apis

export const notificationSetting = (bodyData = {}, showToaster = true) => {
    return Post({
        url: "/user/notification_setting",
        body: bodyData,
        showToaster,
    })
}

export const passwordSetting = (bodyData = {}, showToaster = true) => {
    return Post({
        url: "/user/change-password",
        body: bodyData,
        showToaster,
    })
}

export const CSVFileUpload = (bodyData = {}, showToaster = true) => {
    console.log("body", bodyData)
    const token = store.getState().userAuth.user.Token
    return fetch(`${Config.env().API_URL}/item/bulk-items`, {
        headers: {
            Authorization: token,
        },
        body: bodyData,
        method: "POST",
    }).then(res => handleFetchError(res))
}

export const GetPdfReport = (params = {}, abortSignal = null) => {
    const token = store.getState().userAuth.user.Token
    const url = appendQueryParams(`${Config.env().API_URL}/pdf/pdf`, params)

    return (
        fetch(url, {
            headers: {
                Authorization: token,
                "Content-Type": " application/pdf",
            },
            // body: JSON.stringify(params),
            responseType: "blob",
            method: "GET",
        })
            // .then(response => response.blob())
            .then(res => Promise.resolve(res))
            .catch(err => {})
    )
}

export const GetPdfReportOfItemCatalog = (
    id,
    params = {},
    abortSignal = null
) => {
    const token = store.getState().userAuth.user.Token
    const url = appendQueryParams(
        `${Config.env().API_URL}/pdf/catelog-pdf/${id}`,
        params
    )

    return fetch(url, {
        headers: {
            Authorization: token,
            "Content-Type": " application/pdf",
        },
        responseType: "blob",
        method: "GET",
    })
        .then(res => Promise.resolve(res))
        .catch(err => {})
}

export const GetGlobalQuantityById = (id = "", params = {}) => {
    return Get({ url: `/inventory/items-global-quantities/${id}`, params })
}

export const GetItemCatalogDetailsById = (
    params = {},
    abortSignal = null,
    id = ""
) => {
    return Get({ url: `/item-catalog/department-catelog-items/${id}`, params })
}

export const GetPurchaseOrdersListing = (params = {}, abortSignal = null) => {
    return Get({
        url: "/inventory/purchase-orders",
        params,
        abortSignal,
    })
}

export const GetPurchaseOrdersItems = (
    params = {},
    abortSignal = null,
    id = ""
) => {
    return Get({
        url: `/inventory/purchase-orders-items/${id}`,
        params,
        abortSignal,
    })
}

export const GetRequestPOListing = (
    params = {},
    abortSignal = null,
    id = ""
) => {
    return Get({
        url: `/inventory/purchase-order-pos/${id}`,
        params,
        abortSignal,
    })
}
export const MarkItemsReceiving = (bodyData = {}) => {
    return Post({
        url: "/inventory/warehouse-manifest",
        body: bodyData,
    })
}

export const ValidateRequestReceivingItems = (bodyData = {}) => {
    return Post({
        url: "/inventory/purchase-order-po-validation",
        body: bodyData,
    })
}

export const VendorListing = (params = {}, abortSignal = null) => {
    return Get({
        url: "/vendor/vendors",
        params,
        abortSignal,
    })
}

export const GetItemAuditHistory = (params = {}, abortSignal = null) => {
    return Get({
        url: "/department/item-audit-history",
        params,
        abortSignal,
    })
}

export const CreateItemAudit = (bodyData = {}) => {
    return Post({
        url: "/department/audit-item",
        body: bodyData,
    })
}
