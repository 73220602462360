import React, { useState } from "react"
import { CardBody, Container, Row, Col, Card } from "reactstrap"
import { useHistory } from "react-router-dom"

import { SearchBox } from "components/SearchBox"
import { TableTopHeading } from "components/TableTopHeading"
import { BackButtonTableTop } from "components/BackButtonTableTop"
import { Pagination } from "components/Pagination"
import { DataTable } from "components/DataTable"
import { PageLimit } from "components/PageLimit"
import { Button } from "components/Button"
import { Modal } from "components/Modal"
import { useLocalPaginatedRequest } from "hooks/useLocalPaginatedRequest"
import InventoryTitleIcon from "assets/images/icons/inventory-title-icon.png"
import { getAppName } from "utils/commonUtils"
import { GetDepartmentInventoryListing } from "api/api.service"
import { Refresh } from "components/Refresh"
import useDepartmentConfig from "hooks/useDepartmentConfig"
import { useParams } from "react-router-dom/cjs/react-router-dom.min"

const COLUMNS = {
    SKU_NO: "SKU No.",
    STATION_NAME: "Station Name",
    ITEM_NAME: "Item Name",
    QUANTITY: "Quantity",
}

const DATA_KEY = "data"
const TOTAL_KEY = "total"

const InventoryListingBreakdown = ({}) => {
    const {
        currentDepartmentId,
        departmentIds,
        currentDepartmentName,
        departmentManagerName,
    } = useDepartmentConfig()
    const { itemId } = useParams()
    const history = useHistory()
    const [itemDes, setItemDes] = useState("")

    const [modalStates, setModalStates] = useState({
        showItemDesModal: false,
    })

    const {
        isFetching,
        page,
        searchText,
        pageSize,
        data,
        total,
        request,
        handlePageClick,
        handleSearchChange,
        onChangePageSize,
    } = useLocalPaginatedRequest({
        requestFn: GetDepartmentInventoryListing,
        hasSorting: false,
        params: {
            departmentId: departmentIds,
            itemsId: itemId,
        },
        dataKey: DATA_KEY,
        totalKey: TOTAL_KEY,
    })

    const handleOpenDesModal = data => {
        setItemDes(data)
        setModalStates(prev => ({ ...prev, showItemDesModal: true }))
    }

    const handleCloseDesModal = () => {
        setItemDes("")
        setModalStates(prev => ({
            ...prev,
            showItemDesModal: false,
            viewItemCatalog: false,
        }))
    }

    return (
        <>
            <div className="gt-business-accounts px-3 px-md-5">
                <Container fluid>
                    <Row>
                        <Col className="col-12 ">
                            <div className="d-flex align-items-center mb-2 mb-md-4">
                                <BackButtonTableTop />
                            </div>

                            <TableTopHeading
                                title={`Inventory (${departmentManagerName})`}
                                iconImage={InventoryTitleIcon}
                                iconClass="inventory-req-icon"
                            />

                            <Card className="mb-5">
                                <Refresh
                                    onClick={request}
                                    isDisable={isFetching}
                                />
                                <CardBody className="p-2 p-md-4">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <h5 className="fw-600 mb-0 table-main-heading">
                                            Inventory
                                        </h5>
                                        <div className="d-flex align-items-md-center justify-content-center flex-column flex-md-row align-items-end">
                                            <div>
                                                <SearchBox
                                                    searchText={searchText}
                                                    onChange={
                                                        handleSearchChange
                                                    }
                                                    inputClasses="table-main-search-input inventory-search-ip me-2"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <PageLimit
                                            pageSize={pageSize}
                                            changePageSizeHandler={
                                                onChangePageSize
                                            }
                                            rowLimit={pageSize}
                                            typoClasses="page-limit-typo"
                                            selectClasses="page-limit-select"
                                        />
                                    </div>

                                    <DataTable
                                        data={data}
                                        loading={isFetching}
                                        tableClasses="inventory-table"
                                        config={[
                                            {
                                                title: COLUMNS.SKU_NO,
                                                render: data => {
                                                    return (
                                                        <>
                                                            <div
                                                                onClick={() =>
                                                                    handleOpenDesModal(
                                                                        data
                                                                    )
                                                                }
                                                                className="table-clickable-column"
                                                            >
                                                                {data?.skuNo ||
                                                                    "-"}
                                                            </div>
                                                        </>
                                                    )
                                                },
                                            },
                                            {
                                                title: COLUMNS.STATION_NAME,
                                                render: data => {
                                                    return (
                                                        data?.department
                                                            .departmentName ||
                                                        "-"
                                                    )
                                                },
                                            },

                                            {
                                                title: COLUMNS.ITEM_NAME,
                                                render: data => {
                                                    return data?.itemName || "-"
                                                },
                                            },
                                            {
                                                title: COLUMNS.QUANTITY,
                                                render: data => {
                                                    return (
                                                        <div>
                                                            {data?.inStock}
                                                        </div>
                                                    )
                                                },
                                            },
                                        ]}
                                    />
                                    <Pagination
                                        currentPage={page}
                                        totalData={total}
                                        onPageClick={handlePageClick}
                                        rowLimit={pageSize}
                                    />
                                </CardBody>
                            </Card>
                            <div className="text-center">
                                <Button
                                    size="lg"
                                    className="gt-btn-grad-primary table-bottom-btn btn-alignment mb-3"
                                    title={<div>Back to Main</div>}
                                    onClick={history.goBack}
                                />
                            </div>
                        </Col>
                    </Row>

                    {modalStates.showItemDesModal && (
                        <Modal
                            isOpen={modalStates.showItemDesModal}
                            handleModalToggling={handleCloseDesModal}
                            bodyClassName="p-0"
                            customButton={true}
                            hideModalHeaderSeparator={true}
                            headerClasses="pb-0"
                            className="inv-req-popup-viewdetails"
                            sizeClasses="7"
                        >
                            <h3 className="text-center inv-req-popup-heading">{`${itemDes?.itemName} (${itemDes.skuNo})`}</h3>
                            <div className="p-4">
                                <h4 className="inventory-description">
                                    Product Summary
                                </h4>
                                <p className="mt-1">{`${
                                    itemDes?.description || "-"
                                }`}</p>
                            </div>
                        </Modal>
                    )}
                </Container>
            </div>
        </>
    )
}

export default InventoryListingBreakdown
