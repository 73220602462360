import React, { useState, useEffect } from "react"
import toast from "react-hot-toast"
import { DataTable } from "../../../../components/DataTable"
import { SearchBox } from "../../../../components/SearchBox"
import { PageLimit } from "../../../../components/PageLimit"
import { Pagination } from "../../../../components/Pagination"
import { Modal } from "../../../../components/Modal"
import { TextInput } from "../../../../components/TextInput"
import { useLocalPaginatedRequest } from "../../../../hooks/useLocalPaginatedRequest"
import { AddNewItemModal as CreateOrder } from "../../../Common/AddNewRequestModal"
import {
    GetItemsListing,
    AddWarehouseInventoryRequest,
    GetStockListing,
} from "api/api.service"
import Config from "Config"
import { Button } from "components/Button"
import { Loader } from "components/Loader"

const DATA_KEY = "data"
const TOTAL_KEY = "total"
const COLUMNS = {
    CHECK_BOXES: "",
    SKU_NO: "SKU No.",
    ITEM_NAME: "Item Name",
    QUANTITY: "Quantity / Global Quantity",
}

const GloablQuantityItemsListing = ({ config = {}, closeModal }) => {
    let controller = new AbortController()
    let signal = controller.signal
    const [isAllSelected, setIsAllSelected] = useState(true)
    const [isAddingRequest, setIsAddingRequest] = useState()
    const [checkedItemsList, setCheckedItemsList] = useState([])
    const [modalStates, setModalStates] = useState({
        createOrdersModal: false,
    })
    const [createOrderListing, setCreateOrderListing] = useState({
        data: [],
        fetching: true,
        page: 1,
        Q: "",
        isDataAvailable: false,
    })

    const {
        data,
        total,
        isFetching,
        page,
        searchText,
        pageSize,
        handleSearchChange,
        handlePageClick,
        onChangePageSize,
        setData,
    } = useLocalPaginatedRequest({
        isLoadMore: true,
        requestFn: GetStockListing,
        params: {
            condition: "minorShortage",
            limit: 30,
        },
        dataKey: DATA_KEY,
        totalKey: TOTAL_KEY,
    })

    const handleCheckBoxSelect = ind => {
        let cloned = [...data]

        let obj = cloned[ind]

        if (obj.maxQuantity <= obj.quantity) {
            return toast.error(
                "Cant add this item, Warehouse already has enough quantity"
            )
        }

        if (obj?.isChecked) {
            obj.isChecked = false
            obj.quantityError = ""
            obj.requiredQuantity = ""
        } else {
            obj.isChecked = true
            obj.quantityError = 1
        }

        cloned[ind] = { ...obj }

        let allChecked = cloned.filter(item => item.isChecked)

        setData(cloned)
        setCheckedItemsList(allChecked)
    }

    const handleSelectAll = currState => {
        let cloned = [...data]

        if (cloned.find(item => item.maxQuantity <= item.quantity)) {
            return toast.error(
                "Cant select all item ,as few has enough quantity"
            )
        }

        let afterAllChecked = cloned.map(item => ({
            ...item,
            isChecked: currState,
            quantityError: 1,
        }))
        setData(afterAllChecked)
        setIsAllSelected(prev => !prev)
    }

    const handleCloseModal = () => {
        setModalStates({
            createOrdersModal: false,
        })
        setCheckedItemsList([])
    }

    const handleSearch = (value, callback) => {
        if (!value) return

        callback(prev => {
            return {
                ...prev,
                data: [],
                page: 1,
                Q: value,
            }
        })
    }

    const handleCreateOrdersModalClose = () => {
        setCheckedItemsList([])
        setModalStates({ ...modalStates, createOrdersModal: false })
        closeModal()
    }

    useEffect(() => {
        getCreateOrderListingData(createOrderListing.page)

        return () => {
            controller.abort()
        }
    }, [createOrderListing.Q])

    const getCreateOrderListingData = page => {
        handleStateSet(setCreateOrderListing, { fetching: true, data: [] })
        GetItemsListing(
            {
                limit: 100,
                ...(createOrderListing.Q && { Q: createOrderListing.Q }),
                page: page,
            },
            signal
        )
            .then(res => {
                setCreateOrderListing(prev => ({
                    ...prev,
                    fetching: false,
                    page: page + 1,
                    isDataAvailable: res?.data.length === Config.LIMIT,
                    data: (() => {
                        let test = res?.data.map(item => ({
                            ...item,
                            value: item.id,
                            label: `${item.skuNo} - ${item.itemName}`,
                        }))
                        return [...test, ...prev.data]
                    })(),
                }))
            })
            .finally(() => {
                handleStateSet(setCreateOrderListing, { fetching: false })
            })
    }

    const handleModalInputPriceValueChange = (e, id) => {
        let tempList = [...checkedItemsList]
        tempList.map(obj => {
            if (obj.id === id) {
                const modifiedValue = e.target.value.replace(/[^0-9]*/g, "")
                obj.requiredQuantity = modifiedValue
                if (e.target.value < 0) {
                    obj.quantityError = 2
                    return false
                }
                if (e.target.value > 0 && e.target.value !== "") {
                    obj.quantityError = 0
                } else {
                    obj.quantityError = 1
                }
            }
        })
        setCheckedItemsList(tempList)
    }

    const handleOnSelectOptionModalAutocomplete = e => {
        if (e.maxQuantity <= e.warehouseQuantity) {
            return toast.error(
                "Cant add this item, Warehouse already has enough quantity"
            )
        }

        const tempList = checkedItemsList.filter(obj => obj.skuNo == e.skuNo)
        e.requiredQuantity = ""
        e.quantityError = 1
        !tempList.length && setCheckedItemsList([...checkedItemsList, e])
    }
    const handleModalListDelete = item => {
        const tempList = checkedItemsList.filter(obj => obj.id !== item.id)
        setCheckedItemsList(tempList)
    }

    const handleStateSet = (callback = () => {}, keysObj = {}) => {
        callback(prev => {
            return {
                ...prev,
                ...(keysObj && keysObj),
            }
        })
    }

    const handleCreateOrder = async e => {
        e.preventDefault()
        let errorCheck = [...checkedItemsList]
        errorCheck = checkedItemsList.filter(obj => obj.quantityError !== 0)
        if (errorCheck.length) {
            toast.error("Please insert the valid data in all fields.")
            return
        }
        let bodyData = { type: "Request", warehouseRequestItems: [] }
        checkedItemsList.map(obj => {
            let tempObj = {}
            tempObj.itemsId = obj.itemsId || obj.id
            tempObj.quantity = obj.requiredQuantity * 1
            tempObj.status = "Pending"
            bodyData.warehouseRequestItems.push(tempObj)
        })
        try {
            setIsAddingRequest(true)
            const res = await AddWarehouseInventoryRequest(bodyData)
            toast.success("Request has been created successfully!")
            handleCloseModal()
        } catch (err) {
            // toast.error(err.message)
        } finally {
            setIsAddingRequest(false)
        }
    }

    const handleInitCreateOrder = () => {
        let allChecked = data.filter(item => item.isChecked)

        if (!allChecked.length) {
            return toast.error("No item has been seleted !")
        }

        setCheckedItemsList(allChecked)

        setModalStates({
            ...modalStates,
            createOrdersModal: true,
        })
    }

    return (
        <div className="p-2">
            {/*
            
            Enhancement 
            
            <div className="d-flex align-items-center justify-content-between  ">
                <PageLimit
                    pageSize={pageSize}
                    changePageSizeHandler={onChangePageSize}
                    rowLimit={pageSize}
                    typoClasses="page-limit-typo"
                    selectClasses="page-limit-select"
                />
                <div>
                    <SearchBox
                        searchText={searchText}
                        onChange={handleSearchChange}
                        inputClasses="table-main-search-input wh-im-search-input"
                    />
                </div>
            </div> */}
            <DataTable
                data={data}
                loading={isFetching && page == 1}
                className="bg-header-color mt-3 view-item-detail height-scroll-table p-1"
                tableClasses="wh-im-table"
                config={[
                    {
                        title: (() => (
                            <div className="form-check">
                                <TextInput
                                    disabled={!data?.length}
                                    onClick={() =>
                                        handleSelectAll(isAllSelected)
                                    }
                                    labelClass="mb-0"
                                    type="checkbox"
                                    inputClass="form-check-input-custom"
                                    checked={
                                        !Boolean(
                                            data?.length &&
                                                data?.find(
                                                    item => !item?.isChecked
                                                )
                                        )
                                    }
                                />
                            </div>
                        ))(),
                        render: (data, ind) => {
                            return (
                                <div className="form-check">
                                    <TextInput
                                        onClick={() =>
                                            handleCheckBoxSelect(ind)
                                        }
                                        labelClass="mb-0"
                                        type="checkbox"
                                        inputClass="form-check-input-custom"
                                        checked={data.isChecked}
                                    />
                                </div>
                            )
                        },
                    },
                    {
                        title: COLUMNS.SKU_NO,
                        render: data => {
                            return <>{data?.skuNo}</>
                        },
                    },
                    {
                        title: COLUMNS.ITEM_NAME,
                        render: data => {
                            return data?.itemName
                        },
                    },
                    {
                        title: COLUMNS.QUANTITY,
                        className: "dashboard-view-item-details text-center",

                        render: obj =>
                            (
                                <div
                                    className={`wh-mang-global-quantity bg-red-light`}
                                >
                                    {obj?.warehousequantity || "0"} /{" "}
                                    {obj?.globalquantity || "0"}
                                </div>
                            ) || "-",
                    },
                ]}
            />
            {/*
             <Pagination
                currentPage={page}
                totalData={total}
                onPageClick={handlePageClick}
                rowLimit={pageSize}
            /> 
            */}

            {total > 0 && data.length < total ? (
                <div className="text-center py-2">
                    {isFetching ? (
                        <Loader />
                    ) : (
                        <p
                            className="m-0 p-0 clickable-link-text "
                            onClick={() => handlePageClick(page + 1)}
                        >
                            Load more
                        </p>
                    )}
                </div>
            ) : null}

            <div className="mt-5 mb-2 d-flex align-items-center justify-content-center">
                <Button
                    disabled={total == 0}
                    onClick={handleInitCreateOrder}
                    size="lg"
                    className="gt-btn-grad-primary p-2"
                    title="Create new order"
                />
            </div>

            <Modal
                isOpen={modalStates.createOrdersModal}
                handleModalToggling={handleCreateOrdersModalClose}
                bodyClassName="p-0"
                customButton={true}
                hideModalHeaderSeparator={true}
                headerClasses="pb-0"
                className="inv-req-popup-viewdetails"
                sizeClasses="10"
            >
                <CreateOrder
                    modalCloseHandler={handleCreateOrdersModalClose}
                    title="CREATE ORDER"
                    footerBtnType="Confirm Order"
                    deleteOption={true}
                    isAddingRequest={isAddingRequest}
                    createOrderListing={createOrderListing}
                    handleSearch={handleSearch}
                    setCreateOrderListing={setCreateOrderListing}
                    handleOnSelectOptionModalAutocomplete={
                        handleOnSelectOptionModalAutocomplete
                    }
                    modalDataList={checkedItemsList}
                    handleModalInputPriceValueChange={
                        handleModalInputPriceValueChange
                    }
                    handleModalListDelete={handleModalListDelete}
                    handleCreateOrder={handleCreateOrder}
                />
            </Modal>
        </div>
    )
}

export default GloablQuantityItemsListing
