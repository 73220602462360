import React from "react"

import useDepartmentConfig from "hooks/useDepartmentConfig"
import InventoryListing from "./InventoryListing"
import InventoryGroupedListing from "./InventoryGroupedListing"

const Inventory = () => {
    const { currentDepartmentId } = useDepartmentConfig()

    return Boolean(currentDepartmentId) ? (
        <InventoryListing />
    ) : (
        <InventoryGroupedListing />
    )
}

export default Inventory
