import React, { useState } from "react"
import { CardBody, Container, Row, Col, Card } from "reactstrap"
import { toast } from "react-hot-toast"

import { TableTopHeading } from "components/TableTopHeading"
import { SearchBox } from "components/SearchBox"
import InventoryRequestIcon from "assets/images/warehouse/inventory-request/wh-rm-header-icon.png"
import ActionEyeIcon from "assets/images/inventory-request/action-eye-icon.png"
import { Pagination } from "components/Pagination"
import { DataTable } from "components/DataTable"
import { PageLimit } from "components/PageLimit"
import { AutoComplete } from "components/AutoComplete"
import { Modal } from "components/Modal"
import { useLocalPaginatedRequest } from "hooks/useLocalPaginatedRequest"
import { InventoryReqStatus } from "constants"
import { dateTimeFormat, convertDateTime } from "utils/dateFormatUtils"
import customStyles from "./HeaderStatusStyling"
import {
    GetPullbackRequestListing,
    PullbackFullfillment,
} from "api/api.service"
import PullbackRequestItems from "./PullbackRequestItems"
import { Refresh } from "components/Refresh"
import useDepartmentConfig from "hooks/useDepartmentConfig"

const COLUMNS = {
    REQUEST_NO: "Request No.",
    DATE_OF_REQUEST: "Requested Date",
    DEPARTMENT: "Station",
    STATUS: "Status",
    ACTION: "Actions",
}
const DATA_KEY = "data"
const TOTAL_KEY = "total"

const DepartmentRequestListing = ({}) => {
    const {
        currentDepartmentId,
        departmentIds,
        currentDepartmentName,
        departmentManagerName,
    } = useDepartmentConfig()

    const [isSubmitting, setIsSubmitting] = useState(false)
    const [selectedData, setSelectedData] = useState({})
    const [statusFilters, setStatusFilters] = useState(null)
    const [modalToggle, setModalToggle] = useState(false)

    const {
        data,
        page,
        pageSize,
        total,
        isFetching,
        searchText,
        request,
        handleSearchChange,
        handlePageClick,
        onChangePageSize,
    } = useLocalPaginatedRequest({
        requestFn: GetPullbackRequestListing,
        params: {
            ...(statusFilters?.value && {
                requestStatus: statusFilters?.value,
            }),
            ...(currentDepartmentId
                ? { departmentId: [currentDepartmentId] }
                : { departmentId: departmentIds }),
        },
        deps: [statusFilters, currentDepartmentId],
        dataKey: DATA_KEY,
        totalKey: TOTAL_KEY,
    })

    const statusSelectOptions = [
        {
            label: "Open",
            value: "Open",
        },
        {
            label: "In Progress",
            value: "InProgress",
        },
        {
            label: "Completed",
            value: "Completed",
        },
    ]

    const handleModalToggle = data => {
        setSelectedData(data || {})
        setModalToggle(prev => !prev)
    }
    const handleSelectStatusOption = option => {
        setStatusFilters(option)
    }

    const handleOnSubmit = async payload => {
        let data = { ...payload }

        try {
            setIsSubmitting(true)
            let res = await PullbackFullfillment(data)
            handleModalToggle()
            toast.success("Request has been fulfilled")
            request()
        } catch (err) {
            toast.err(err.message)
        } finally {
            setIsSubmitting(false)
        }
    }

    return (
        <>
            <div className="gt-business-accounts ">
                <Container fluid>
                    <Row>
                        <Col className="col-12">
                            <div className="d-flex align-items-center mb-4 mt-lg-0 mt-3 justify-content-between">
                                <TableTopHeading
                                    title={"Inventory Requests"}
                                    className="me-4"
                                    iconImage={InventoryRequestIcon}
                                    iconClass="inventory-req-icon"
                                />
                                <div className="">
                                    <div className="status-search-con">
                                        <span className="status-label mx-2">
                                            Status :
                                        </span>
                                        <AutoComplete
                                            value={statusFilters}
                                            isClearable
                                            onChange={val =>
                                                handleSelectStatusOption(val)
                                            }
                                            isSearchable={false}
                                            placeholder="Select status"
                                            customStyles={
                                                customStyles.InventoryHeader
                                            }
                                            classNamePrefix="status-header-search"
                                            options={statusSelectOptions}
                                        />
                                    </div>
                                </div>
                            </div>

                            <Card className="mb-5">
                                <Refresh
                                    onClick={request}
                                    isDisable={isFetching}
                                />

                                <CardBody className="p-2 p-md-4">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <h5 className="fw-600 mb-0 table-main-heading">
                                            Requests
                                        </h5>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-between  ">
                                        <PageLimit
                                            pageSize={pageSize}
                                            changePageSizeHandler={
                                                onChangePageSize
                                            }
                                            rowLimit={pageSize}
                                            typoClasses="page-limit-typo"
                                            selectClasses="page-limit-select"
                                        />
                                        <div>
                                            <SearchBox
                                                searchText={searchText}
                                                onChange={handleSearchChange}
                                                // labelClass="wh-im-search-label"
                                                inputClasses="table-main-search-input wh-im-search-input"
                                                // placeholder='Search...'
                                            />
                                        </div>
                                    </div>
                                    <DataTable
                                        data={data}
                                        loading={isFetching}
                                        config={[
                                            {
                                                title: COLUMNS.REQUEST_NO,
                                                render: data => data.id,
                                            },
                                            {
                                                title: COLUMNS.DATE_OF_REQUEST,
                                                render: data =>
                                                    convertDateTime({
                                                        date: data.createdAt,
                                                        customFormat:
                                                            dateTimeFormat.casitaCutomDateFormat,
                                                        dateOnly: true,
                                                    }),
                                            },
                                            {
                                                title: COLUMNS.DEPARTMENT,
                                                render: data =>
                                                    data?.departmentName,
                                            },
                                            {
                                                title: COLUMNS.STATUS,
                                                render: data => {
                                                    return (
                                                        <div
                                                            className={`inv-req-popup-status ${InventoryReqStatus.getLabelClass(
                                                                data.status
                                                            )}`}
                                                        >
                                                            {InventoryReqStatus.getDisplayTextKey(
                                                                data.status
                                                            )}
                                                        </div>
                                                    )
                                                },
                                            },
                                            {
                                                className: "text-center",
                                                title: COLUMNS.ACTION,
                                                render: data => {
                                                    return (
                                                        <span
                                                            className="text-success pointer"
                                                            onClick={() =>
                                                                handleModalToggle(
                                                                    data
                                                                )
                                                            }
                                                        >
                                                            <img
                                                                src={
                                                                    ActionEyeIcon
                                                                }
                                                            />
                                                        </span>
                                                    )
                                                },
                                            },
                                        ]}
                                    />
                                    <Pagination
                                        currentPage={page}
                                        totalData={total}
                                        onPageClick={handlePageClick}
                                        rowLimit={pageSize}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Modal
                styles={{ minHeight: "60vh" }}
                isOpen={modalToggle}
                handleModalToggling={handleModalToggle}
                bodyClassName="p-0"
                customButton={true}
                hideModalHeaderSeparator={true}
                headerClasses="pb-0"
                className="inv-req-popup-viewdetails"
                sizeClasses="10"
            >
                <PullbackRequestItems
                    isSubmitting={isSubmitting}
                    modalCloseHandler={handleModalToggle}
                    data={selectedData}
                    onSubmit={handleOnSubmit}
                />
            </Modal>
        </>
    )
}

export default DepartmentRequestListing
